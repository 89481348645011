var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('div',{staticClass:"main"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"acinfo"},[_vm._v(_vm._s(_vm.$t('Account.information')))]),_c('div',{staticClass:"one"},[_c('span',[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('mobile.number'))+"/"+_vm._s(_vm.$t('email')))]),_c('div',{staticClass:"account"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keywords),expression:"keywords"}],attrs:{"type":"text"},domProps:{"value":(_vm.keywords)},on:{"focus":function($event){return _vm.qcac()},"input":function($event){if($event.target.composing){ return; }_vm.keywords=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tag),expression:"tag"}],staticClass:"acone"},[_vm._v(_vm._s(_vm.$t('Account.number')))]),_c('div',{staticClass:"two"},[_c('span',[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('code')))]),_c('div',{staticClass:"code"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],attrs:{"type":"text"},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.code=$event.target.value}}}),_c('button',{staticClass:"ant-btn",attrs:{"type":"button"},on:{"click":function () {
                    !function(e) { return e.preventDefault(); }
                    _vm.sendCode()
                    }}},[_c('span',[_vm._v(_vm._s(_vm.count))])])]),_c('div',{staticClass:"three"},[_c('span',[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('password')))]),_c('div',{staticClass:"pwd"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pwd),expression:"pwd"}],attrs:{"type":"password","placeholder":_vm.$t('case.sensitive')},domProps:{"value":(_vm.pwd)},on:{"blur":function () {
                    !function(e) { return e.preventDefault(); }
                    _vm.Checkpwd()
                    },"focus":function($event){return _vm.qcpwd()},"input":function($event){if($event.target.composing){ return; }_vm.pwd=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pwdtag),expression:"pwdtag"}],staticClass:"acone"},[_vm._v(_vm._s(_vm.$t('password.incorrect')))]),_c('div',{staticClass:"four"},[_c('span',[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('Confirm.password')))]),_c('div',{staticClass:"rwd"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rwd),expression:"rwd"}],attrs:{"type":"password"},domProps:{"value":(_vm.rwd)},on:{"blur":function () {
                    !function(e) { return e.preventDefault(); }
                    _vm.Checkrwd()
                    },"focus":function($event){return _vm.qcrwd()},"input":function($event){if($event.target.composing){ return; }_vm.rwd=$event.target.value}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.rwdtag),expression:"rwdtag"}],staticClass:"acone"},[_vm._v(_vm._s(_vm.$t('two.passwords')))]),_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.regsub()}}},[_vm._v(_vm._s(_vm.$t('register')))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.subtag),expression:"subtag"}],staticClass:"acone"},[_vm._v(_vm._s(_vm.$t('correctly')))])])])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }