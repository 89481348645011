<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="info">
                <div class="list">
                    <div class="acinfo">{{ $t('Account.information') }}</div>
                    <div class="one"><span>*</span>{{ $t('mobile.number') }}/{{ $t('email') }}</div>
                    <div class="account"><input type="text" v-model="keywords" @focus="qcac()"></div>
                    <div class="acone" v-show="tag">{{ $t('Account.number') }}</div>
                    <div class="two"><span>*</span>{{ $t('code') }}</div>
                    <div class="code">
                        <input type="text" v-model="code">
                        <button  type="button" class="ant-btn"  @click="() => {
                        e => e.preventDefault()
                        sendCode()
                        }"><span>{{count}}</span></button>
                    </div>
                    <div class="three"><span>*</span>{{ $t('password') }}</div>
                    <div class="pwd"><input type="password" @blur="() => {
                        e => e.preventDefault()
                        Checkpwd()
                        }" v-model="pwd" :placeholder="$t('case.sensitive')" @focus="qcpwd()"/></div>
                    <div class="acone" v-show="pwdtag">{{ $t('password.incorrect') }}</div>
                    <div class="four"><span>*</span>{{ $t('Confirm.password') }}</div>
                    <div class="rwd"><input type="password" @blur="() => {
                        e => e.preventDefault()
                        Checkrwd()
                        }" v-model="rwd"  @focus="qcrwd()"/></div>
                    <div class="acone" v-show="rwdtag">{{ $t('two.passwords') }}</div>
                    <button class="submit" @click="regsub()">{{ $t('register') }}</button>
                    <div class="acone" v-show="subtag">{{ $t('correctly') }}</div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
   name: "Register",
   components: {
    Footer,
    Header
  },
   data () {
    return {
        keywords: '',
        categoryId: this.$route.params.categoryId,
        show: true,
        count: '获取验证码',
        timer: null,
        tag: false,
        pwd: '',
        pwdtag: false,
        rwd: '',
        rwdtag: false,
        code: '',
        subtag: false
    }
  },
  methods: {
    // 发送验证码
    sendCode(keywords){
      if (this.keywords == ''){
        this.tag = true;
        return ;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            }
        }, 1000)
      }
      const params = {
          account: this.keywords,
          type: 'register',
      }
      this.$common.handlePost('/code/send', params).then(data => {
        if (data.code === 200) {
          this.$layer.alert("发送验证码成功");
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
     //去除提示信息
    qcac() {
        this.tag = false
    },
    // 验证密码
    Checkpwd() {
      const FloatRegex  = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/
      if (!FloatRegex.test(this.pwd)) {
        this.pwdtag = true
        return
      }
    },
    //去除提示信息
    qcpwd() {
        this.pwdtag = false
    },
    //确认密码是否一致
    Checkrwd(){
        if (this.pwd != this.rwd){
            this.rwdtag = true
        }
    },
    //去除提示信息
    qcrwd() {
        this.rwdtag = false
    },
    //注册
    regsub() {
        if (this.keywords == '' || this.pwd == '' || this.categoryId == '' || this.code == '') {
            this.subtag = true
            return 
        }
        const params = {
            account: this.keywords,
            password: this.pwd,
            categoryId: this.categoryId,
            code: this.code
        }
        this.$common.handlePost('/user/regist', params).then(data => {
        if (data.code === 200) {
            // this.$layer.alert("注册成功");
            this.$router.push({name: 'Register2', params: {userId: data.data.userId}})
        } else {
          this.$layer.alert(data.msg);
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/register.less';
</style>